import React, { useEffect } from "react";
import { Modal } from "antd";
import ButtonFill from "../ButtonFill/ButtonFill";
const AddModal = ({ isOpen, setIsOpen, setIndex, index }) => {
  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // Close the modal if index is 0
    if (index === 0) {
      setIsOpen(false);
    }
  }, [index, setIsOpen]);

  return (
    <>
      <Modal open={isOpen} onCancel={handleCancel} footer={null}>
        <div className="d-flex flex-column align-items-center py-4 gap-3">
          <span className="nav-title">Add Shop</span>
          <span className="w-75">
            {/* <ButtonFill title="Bulk Add" onclick={() => setIndex(1)} /> */}
          </span>
          <span className="w-75">
            <ButtonFill title="Add Shop" onclick={() => setIndex(2)} />
          </span>
        </div>
      </Modal>
    </>
  );
};
export default AddModal;
