import React, { useEffect, useState } from "react";
import MainLayout from "../MainLayout/MainLayout";
import "./Subscriber.css";
import Table from "../Table/Table";
import { HiOutlineDotsVertical } from "react-icons/hi";
import AddShopForm from "./AddShopForm/AddShopForm";
import ButtonUnfill from "../ButtonUnfill/ButtonUnfill";
import AddModal from "../AddModal/AddModal";
import ButtonFill from "../ButtonFill/ButtonFill";
import {
  getDocs,
  collection,
  db,
  deleteDoc,
  doc,
  getDoc,
} from "../../firebase/firebaseConfig";
import { RotatingLines } from "react-loader-spinner";

// Add Modal component inside the same file
const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Are you sure you want to delete this shop?</h3>
        <div className="modal-buttons">
          <button className="btn btn-confirm w-25" onClick={onConfirm}>
            Yes
          </button>
          <button className="btn btn-cancel w-25" onClick={onClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const Subscriber = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [combinedData, setCombinedData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Add search term state
  // new state add uses in view case:
  const [selectedShop, setSelectedShop] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  // New state for delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [shopToDelete, setShopToDelete] = useState(null); // Store the shop info for deletion

  const [addShop, setAddShop] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    shopName: "",
    shopAddress: "",
    shopCity: "",
    shopCountry: "",
    shopDistrict: "",
    shopPostalCode: "",
    companyName: "",
    description: "",
    shopCategory: "",
  });

  useEffect(() => {
    try {
      setLoader(true);
      const getUserData = async () => {
        const getAllUsers = await getDocs(collection(db, "users"));
        const getAllShops = await getDocs(collection(db, "shops"));

        const usersData = getAllUsers.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const shopsData = getAllShops.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const combined = [];

        shopsData.forEach((shop) => {
          const user = usersData.find((user) => user.shopId.includes(shop.id));

          let status = "Inactive";
          if (user && user.subEndDate) {
            const subEndDate = new Date(user.subEndDate);
            const currentDate = new Date();
            status = subEndDate > currentDate ? "Active" : "Inactive";
          }

          combined.push({
            id: shop.id, // Make sure to pass the shop's id for deletion
            userId: user ? user.id : null, // Include userId if necessary
            userName: user ? user.name : "N/A",
            subEndDate: user ? user.subEndDate : "N/A",
            shopName: shop.name,
            shopAddress: shop.address,
            shopCity: shop.city,
            shopCountry: shop.country,
            shopCategories: shop.category,
            status: status,
            action: <HiOutlineDotsVertical cursor="pointer" />,
          });
        });

        setCombinedData(combined);
        setLoader(false);
      };

      getUserData();
    } catch (e) {
      setLoader(false);
    }
  }, [index]);

  // Filter combinedData based on search term
  const filteredData = combinedData.filter((item) => {
    return (
      item.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.shopName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.shopAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.shopCity.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.shopCountry.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.shopCategories.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleViewClick = async (shop) => {
    try {
      setLoader2(true);
      // Assuming the shop data already contains the uid (shop.userId or shop.id)
      const shopId = shop.userId;

      // Fetch the user data and shop data by the same uid
      const userDoc = await getDoc(doc(db, "users", shopId));
      const shopDoc = await getDoc(doc(db, "shops", shop.id));

      if (userDoc.exists() && shopDoc.exists()) {
        let userInfo = userDoc.data();

        // Check if the shopDoc.id exists in the userInfo.shopId array
        if (userInfo.shopId.includes(shopDoc.id)) {
          // Renaming keys to avoid conflicts between user and shop data
          const userData = {
            id: userDoc.id,
            userName: userInfo.name, // Renaming the user 'name' field
            ...userDoc.data(), // Add other user fields here
          };

          const shopData = {
            id: shopDoc.id,
            // shopName: shopDoc.data().name, // Renaming the shop 'name' field
            ...shopDoc.data(),
          };

          const combinedData = {
            ...userData,
            ...shopData,
          };

          // Pass the combined data to the AddShopForm
          setSelectedShop(combinedData);
          setIsViewMode(true);
          setIndex(2);
          setLoader2(false);
        } else {
          console.log(
            "Shop ID does not match with any entry in the userInfo.shopId array"
          );
        }
      } else {
        console.log("No such documents found!");
      }
    } catch (error) {
      console.error("Error fetching user or shop data: ", error);
      setLoader2(false);
    }
  };

  const handleEditClick = async (shop) => {
    try {
      setLoader2(true);
      const shopId = shop.userId;

      // Fetch user data and shop data by the same uid
      const userDoc = await getDoc(doc(db, "users", shopId));
      const shopDoc = await getDoc(doc(db, "shops", shop.id));

      if (userDoc.exists() && shopDoc.exists()) {
        let userInfo = userDoc.data();

        // Check if the shopDoc.id exists in the userInfo.shopId array
        if (userInfo.shopId.includes(shopDoc.id)) {
          const userData = {
            id: userDoc.id,
            userName: userInfo.name,
            ...userDoc.data(), // Add other user fields if necessary
          };

          const shopData = {
            id: shopDoc.id,
            ...shopDoc.data(),
          };

          const combinedData = {
            ...userData,
            ...shopData,
          };

          // Pass the combined data to the AddShopForm
          setSelectedShop(combinedData);
          setIsEditMode(true); // Enable edit mode
          setIndex(2); // Navigate to the form
          setLoader2(false);
        } else {
          console.log(
            "Shop ID does not match with any entry in the userInfo.shopId array for Edit"
          );
        }
      } else {
        console.log("No such documents found for Edit!");
      }
    } catch (error) {
      console.error("Error fetching user or shop data for Edit: ", error);
      setLoader2(false);
    }
  };

  const confirmDelete = async () => {
    try {
      if (shopToDelete) {
        await deleteDoc(doc(db, "shops", shopToDelete.id));
        setCombinedData((prevData) =>
          prevData.filter((item) => item.id !== shopToDelete.id)
        );
        setIsDeleteModalOpen(false); // Close the modal after deletion
      }
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const handleClick = async (action, data) => {
    if (action === "delete") {
      setShopToDelete(data);
      setIsDeleteModalOpen(true); // Open the delete confirmation modal
    } else if (action === "view") {
      handleViewClick(data);
    } else if (action === "edit") {
      handleEditClick(data);
    }
  };

  const tableHeaders = [
    "Subscriber",
    "Shop Name",
    "Shop Address",
    "Shop City",
    "Shop Country",
    "Shop Categories",
    "Status",
    "Action",
  ];

  const resetForm = () => {
    setAddShop({
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phoneNumber: "",
      shopName: "",
      shopAddress: "",
      shopCity: "",
      shopCountry: "",
      shopDistrict: "",
      shopPostalCode: "",
      companyName: "",
      description: "",
      shopCategory: "",
    });
  };

  const backHandler = () => {
    resetForm();
    setIndex(0);
    setIsOpen(false);
    setIsViewMode(false);
    setIsEditMode(false);
  };

  return (
    <MainLayout setSearchTerm={setSearchTerm}>
      {index === 0 && (
        <section className="content p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="all-subscribe">All Subscriber</span>
            <ButtonFill
              fillBtnWidth="159px"
              title="Add Shop"
              onclick={() => setIsOpen(true)}
            />
          </div>
          <Table
            headers={tableHeaders}
            tableData={
              filteredData.length > 0 ? (
                filteredData
              ) : (
                <div className="no-data-found">No Data Found</div>
              )
            }
            loader={loader}
            subscriberTable={true}
            handleClick={handleClick}
          />
          {isOpen && (
            <AddModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              setIndex={setIndex}
            />
          )}
        </section>
      )}
      {index === 2 && (
        <section className="content p-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="all-subscribe">Add Shop</span>
            <ButtonUnfill
              unfillBtnWidth="159px"
              title="Back"
              onclick={backHandler}
            />
          </div>
          <AddShopForm
            shopData={isEditMode || isViewMode ? selectedShop : ""}
            isViewMode={isViewMode}
            isEditMode={isEditMode}
            setIndex={setIndex}
            addShop={addShop}
            setAddShop={setAddShop}
            resetForm={resetForm}
            fromSubscriber={true}
            setIsOpen={setIsOpen}
          />
        </section>
      )}
      {loader2 && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.2)", // semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000, // ensures it is above other content
            backdropFilter: "blur(1px)", // blurs the background
          }}
        >
          <RotatingLines
            visible={true}
            height="40"
            width="40"
            color="grey"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
            strokeColor="#18432d"
          />
        </div>
      )}
      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDelete}
      />
    </MainLayout>
  );
};

export default Subscriber;
